document.addEventListener('DOMContentLoaded', function () {
    document.body.classList.remove('opaque');

    cleanseInputs();

    let datepickers = document.querySelectorAll("div.datepicker");
    for(let i = 0; i < datepickers.length; i++) {
        let datepickerInput = datepickers[i].querySelector("input[type='text']");
        datepickerInput.setAttribute("placeholder", "dd-mm-jjjj");
    }

    // Scroll behavior polyfill. Only runs in browsers that do not support smooth scroll behavior. It turns css smooth scroll into JS click events
    if ('scrollBehavior' in document.documentElement.style && window.__forceSmoothScrollPolyfill__ !== true) {
        return;
    } else {
        scrollBehaviorSmooth();
    }
});

function scrollBehaviorSmooth() {
    let tabList = document.querySelector(".tabs[role='tablist']");
    let tabs = tabList.getElementsByTagName("a");

    for(let i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", function() {
            let anchorHref = tabs[i].getAttribute("href").substring(1);
            let anchorQuery = "a[name='" + anchorHref + "']";
            document.querySelector(anchorQuery).scrollIntoView({ behavior: 'smooth' });
        })
    }
}

function cleanseInputs() {

    let wmFields = document.getElementsByClassName("wm-field");
    let wmFieldInput;
    let wmFieldLabelWrapper;
    let wmFieldLabel;

    for(let i = 0; i < wmFields.length; i++) {
        wmFieldInput = wmFields[i].querySelector(".wm-field-input");

        if (wmFieldInput && (
            wmFieldInput.querySelector('input[type=text]') ||
            wmFieldInput.querySelector('input[type=email]') ||
            wmFieldInput.querySelector('input[type=tel]') ||
            wmFieldInput.querySelector('input[type=password]') ||
            wmFieldInput.querySelector('input[type=number]') ||
            wmFieldInput.querySelector("textarea"))) {

            if (wmFieldInput.firstElementChild.getAttribute("placeholder") !== null) {
                continue;
            }

            wmFieldInput.firstElementChild.setAttribute("placeholder", " ");
            wmFieldLabelWrapper = wmFieldInput.previousElementSibling;

            if (!wmFieldLabelWrapper) {
                continue;
            }

            wmFieldLabel = wmFieldLabelWrapper.querySelector("label");
            wmFieldInput.appendChild(wmFieldLabel);
            wmFieldInput.previousElementSibling.remove();
        }
    }
}

getOfferCardSliderOptions = function() {
    return {
        grabCursor: true,
        preloadImages: false,
        loop: true,
        threshold: 5,
        lazy: {
            loadPrevNext: true,
            loadOnTransitionStart: true,
        },
    }
}